@import '../../theme/main.scss';

.goal-row {
  white-space: pre-line;

  &-actions {
    min-width: 260px;
  }
  .frequency-per-week {
    width: 10%;
  }
}
.goal-overview {
  .calender-row {
    background-color: #f5f5f7;
    padding: 10px;
  }
  .goal-item {
    &-cursor {
      cursor: pointer;
    }
  }

  &-sub {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    @include media-breakpoint-down(lg) {
      flex-direction: column !important;
    }
  }

  .provider-card-container {
    padding: 16px 89px 16px 20px;
    border-radius: 4px;
    border: 1px solid $gray-200;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $blue-scale-800;
      cursor: pointer;
      border-radius: 100px;
    }

    &-selected {
      border: 1px solid $blue-scale-800 !important;
    }
  }

  .chip {
    border-radius: 5px;
    padding: 9px 5px;
    min-width: 45px;
    font-size: 14px;
    font-weight: 500;
    color: $blue-scale-800;
    display: flex;
    justify-content: center;
    align-items: center;

    &-picked {
      background-color: $blue-scale-800 !important;
      border: 1px solid $blue-scale-800;
      border-radius: 100px;
    }

    &-slot {
      width: 109px;
    }
    &-with-border {
      border: 1px solid $gray-200;
      border-radius: 100px;
      background-color: rgba(90, 137, 255, 0.4);
    }

    &-no-border {
      border: none;
    }

    &-disabled {
      color: $gray-200;
      pointer-events: none;
    }

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      background-color: $gray-200;
      border-radius: 100px;
    }
    &-no-hover {
      &:hover,
      &:active,
      &:focus {
        background-color: transparent;
      }
    }
  }

  tr {
    display: flex;
    justify-content: center;
  }

  &-disabled {
    th {
      padding: 9px 5px;
      min-width: 45px;
      height: 45px;
      color: $gray-200;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      background-color: $gray-200;
    }
  }

  .control-header {
    svg {
      color: $blue-5;
    }

    svg:hover {
      cursor: pointer;
    }
  }
  .gap-8 {
    gap: 8px;
    justify-content: center;
  }

  th {
    padding: 9px 5px;
    min-width: 45px;
    height: 45px;
    color: $gray-200;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  td,
  th {
    height: 45px;
    margin-right: 10px;
    min-width: 45px;
  }
}

.option-button {
  width: 100%;
  border-radius: 15px !important;
  text-transform: uppercase;

  svg {
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:active {
    color: white;
  }
}

@import '../../theme/main.scss';

.post-appointment {
  .appointment-notes-table {
    &-container {
      width: auto;
      max-width: 750px;
      margin: auto;
    }

    &-cell {
      width: 30%;

      &-small {
        width: 120px;
      }
    }
  }

  .space-8 {
    margin-left: 8px;
  }

  .title {
    font-size: 14px !important;
    font-family: Inter-Medium !important;
  }

  .back {
    width: 90px;
    cursor: pointer;
  }

  .back-spacer {
    width: 90px;
  }

  .space-8 {
    margin-left: 8px;
  }

  .title {
    font-size: 14px !important;
    font-family: Inter-Medium !important;
  }

  .sub-title {
    font-size: 14px !important;
    font-family: Inter !important;
    color: #58596a;
  }

  .navigation {
    background-color: white;

    .stepper {
      &-step {
        .title {
          position: relative;
          width: 30px;
          text-align: center;
          white-space: nowrap;
          top: 8px;

          h1 {
            margin-left: -100%;
            margin-right: -100%;
          }
        }
      }
    }

    .check {
      border: 1px solid $gray-500;
      border-radius: 200px;
      width: 30px;
      height: 30px;
      &-current {
        border: 2px solid $blue-5;
      }
      svg path {
        fill: white !important;
        color: white !important;
      }

      &-filled {
        background-color: $blue-5 !important;
        border: 2px solid $blue-5;
      }

      @include media-breakpoint-down(md) {
        width: 20px;
        height: 20px;
        svg {
          width: 15px;
          height: 15px;
          transform: scale(0.6);
        }
      }
    }

    .horizontal-line {
      width: 70px;
      height: 1px;
      background-color: $gray-500;
      &-active {
        background-color: $blue-5;
        height: 2px;
      }
    }
    .space-5 {
      width: 100px;
    }
  }

  .button-primary {
    background-color: $blue-scale-800;
    &:hover {
      background-color: $blue-scale-800;
    }
  }
  .button-secondary {
    background: #ffffff;
    border: 1px solid #141743;
    border-radius: 4px;
    color: $blue-scale-800;
  }
  .tagging {
    .tags {
      max-width: 60%;
    }
    .select-option {
      font-family: Inter-Medium;
      color: $blue-scale-800;
      border: 1px solid #cdcedf;
      padding: 6px 27px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
      }
      &-selected {
        background-color: $blue-5 !important;
        color: #ffffff;
      }
      &-member {
        background-color: #cdcedf;
        cursor: pointer;
      }
    }
  }

  .tests {
    position: relative;

    .description {
      max-width: 40vw;
      font-family: Inter !important;
      font-size: 14px;
    }
    .no-follow-up {
      text-decoration: underline;
      font-family: Inter-Medium !important;
      font-size: 14px;
      cursor: pointer;
    }
    .no-follow-up-dialog {
      position: absolute;
      top: 0px;
      width: 29vw;
      background-color: #ffffff;
      padding: 14px 19px 26px 9px;
      border: 0.5px solid #000000;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      @include media-breakpoint-down(lg) {
        width: 45vw;
      }
      .text {
        font-family: Inter;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: start;
        &-sub {
          font-size: 11px !important;
        }
      }
    }
  }

  .recommended-program {
    position: relative;

    .description {
      max-width: 40vw;
      font-family: Inter !important;
      font-size: 14px;
    }
  }

  .timeline {
    .max-width {
      max-width: 40%;
    }
    .description {
      font-size: 14px !important;
      font-family: Inter !important;
      color: #58596a;
    }
    .note {
      font-size: 12px !important;
      font-family: Inter !important;
      color: #58596a;
      font-style: italic;
      width: 90%;
    }
    .select {
      width: 50%;
    }
  }

  .note {
    .label {
      font-size: 14px;
      color: #58596a;
      font-family: Inter;
    }

    .container {
      max-width: 60%;
    }
    .attachment {
      text-align: end;
      cursor: pointer;
    }
  }

  .review {
    .container {
      max-width: 60%;
    }
    .label {
      font-size: 14px;
      font-weight: bold;
      font-family: Inter-Bold;
      color: black;
    }
    .attachment-check {
      text-decoration: underline;
    }
    .save-as-draft {
      text-decoration: underline;
      font-family: Inter-Medium !important;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .initial {
    .text {
      font-size: 14px !important;
      font-family: Inter !important;
      color: #58596a;
    }
  }
  pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
    font-family: 'Inter-Medium';
  }
}
